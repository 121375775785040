@import './../../../../scss/theme-bootstrap';

$color-quickshop-background: #fff5f5;

.product-quickshop-wrapper {
  display: none;
}

.product-quickshop {
  position: fixed;
  top: 0;
  #{$ldirection}: 0;
  width: 80vw;
  //height: 100%;
  background: $color-quickshop-background;
  z-index: 9999;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @include breakpoint($large-up) {
    top: 50%;
    #{$ldirection}: 50%;
    transform: translate(-50%, 0%);
    background: transparent;
    position: absolute;
    height: auto;
    overflow: visible;
    width: 350px;
  }

  // Normally the quickshop is placed via js. For mobile, center it in window.
  @include breakpoint($medium-down) {
    top: 50%;
    #{$ldirection}: 50% !important;
    transform: translate(-50%, -50%);
  }
  .qs-overlay-cbox & {
    position: static;
    height: auto;
    overflow: visible;
    z-index: 0;
    background: transparent;
    -webkit-overflow-scrolling: auto;
  }
  .product-shade-picker {
    padding: 0 15px 5px;
    flex-direction: column;

    $product-brief-hex-size: 25px;
    $product-brief-shade-size: 25px + (2px * 2);
    $initial-show: 16 + 1; // 2 rows
    display: inline-block;
    align-items: flex-start;
    justify-content: center;
    &__shade_families {
      display: none;
    }
    &__shades {
      display: inline-block;
      width: auto;
      flex-wrap: wrap;
      white-space: normal;
      float: none;
    }
    &__shade-item {
      float: none;
      width: auto;
      height: auto;
      margin: 3px 4px;
    }
    &__shade {
      &__outer {
        width: $product-brief-shade-size;
        height: $product-brief-shade-size;
      }
      &__inner {
        width: $product-brief-hex-size;
        height: $product-brief-hex-size;
      }
    }
    &.has-shade-overflow .product-shade-picker__shade-overflow-link {
      align-self: center;
      display: block;
    }
    &.show-shade-overflow .product-shade-picker__shade-item {
      visibility: visible;
      opacity: 1;
      height: auto;
      margin: 3px 4px;
    }
  }
}

.product-quickshop {
  .product-brief {
    &__name {
      min-height: auto;
    }
  }
  &__price {
    .product-sku-price {
      &__value {
        @include title-small;
        font-size: 10px;
        @include breakpoint($large-up) {
          font-size: 16px;
          line-height: get-line-height(16px, 23px);
        }
      }
      &__unit-price {
        display: block;
        font-family: $font--proxima-nova;
        font-size: 12px;
        line-height: get-line-height(12px, 16px);
        color: $color-dark-gray;
      }
    }
  }
}

#quickshop-overlay-locked-background {
  position: fixed;
  top: 0px;
  #{$ldirection}: 0px;
  width: 100%;
  height: 100%;
  background-color: $color-black;
  opacity: 0.3;
  z-index: 1111;
}

.product-quickshop__container {
  position: relative;
  background: $color-quickshop-background;
  text-align: center;
  padding: 20px 0;
  min-height: 100%;
  @include breakpoint($large-up) {
    background: $color-quickshop-background;
    margin: 0 auto;
    padding-top: 0;
  }
  .flex-row {
    display: flex;
    align-items: center;
  }
  .qs-overlay-cbox & {
    background: $color-quickshop-background;
    padding: 20px 0;
    max-width: 100%;
    @include breakpoint($large-up) {
      @include pie-clearfix;
      padding: 0 20px;
    }
  }
}

.product-quickshop__media {
  position: relative;
  .qs-overlay-cbox & {
    @include breakpoint($large-up) {
      float: $ldirection;
      width: 50.72897%;
      margin-#{$rdirection}: 1.14019%;
      margin-bottom: 0;
      padding: 40px 0;
    }
  }
}

.product-quickshop__content {
  .qs-overlay-cbox & {
    @include breakpoint($large-up) {
      float: $ldirection;
      width: 45.99065%;
      margin-#{$rdirection}: 2.14019%;
      max-width: 410px;
      padding: 40px 0;
    }
  }
}

.product-quickshop__header {
  margin: 13px 0;
  padding: 0 30px;
  @include breakpoint($large-up) {
    padding: 0 13.25%;
    .qs-overlay-cbox & {
      padding: 0;
    }
  }
}

.product-quickshop__price {
  margin: 5px 0 0;
  @include breakpoint($large-up) {
    margin: 0;
  }
  .qs-overlay-cbox & {
    @include breakpoint($large-up) {
      margin: 10px 0 20px;
    }
  }
}

.product-quickshop__inventory-status {
  margin: 15px 0;
  .product__inventory-status {
    @include breakpoint($large-up) {
      font-size: 14px;
    }
  }
}

.product-quickshop__rating {
  margin: 0 0 15px;
}

.product-add-to-cart {
  margin-top: 0;
}
.product-quickshop__cta {
  margin: 0 0 15px;
  text-align: center;
  @include breakpoint($large-up) {
    width: 100%;
    text-align: $ldirection;
  }
  .button {
    min-width: auto;
    height: 52px;
    width: 100%;
    margin: 0 0 10px;
    padding: 0;
    line-height: get-line-height(14px, 52px);
    @include box-shadow(none);
    @include breakpoint($large-up) {
      position: relative;
      top: 0;
    }
  }
  &.add-to-cart-row {
    display: flex;
    @include breakpoint($large-up) {
      margin-top: 10px;
    }
    .product-qty-select {
      margin-top: 0;
      margin-#{$rdirection}: 10px;
      a.selectBox {
        height: 52px;
      }
    }
  }
  &.add-favorites {
    text-align: $rdirection;
    @include breakpoint($large-up) {
      text-align: $ldirection;
    }
  }
}

.product-quickshop__favorites {
  display: none;
  position: absolute;
  bottom: 38px;
  #{$ldirection}: 38px;
  @include breakpoint($large-up) {
    bottom: 19px;
  }
  @include breakpoint($large-up) {
    bottom: 28px;
  }
  .qs-overlay-cbox & {
    @include breakpoint($large-up) {
      bottom: 65px;
      #{$ldirection}: 15px;
    }
    @include breakpoint($large-up) {
      #{$ldirection}: 100px;
      bottom: 80px;
    }
  }
}

.product-quickshop__form {
  .waitlist-form,
  .waitlist-form__full {
    .site-email-signup__gdpr-link {
      font-size: 12px;
      line-height: get-line-height(12px, 20px);
      text-align: center;
      top: -15px;
    }
    .waitlist-form__full {
      margin: 20px 0;
    }
  }
}

.product-quickshop__footer {
  padding: 0 15px;
}

.product-quickshop__close {
  background: transparent;
  border: 0;
  cursor: pointer;
  fill: $color-gold;
  padding: 7px;
  position: fixed;
  #{$rdirection}: 0;
  text-decoration: none;
  top: 12px;
  @include breakpoint($large-up) {
    position: absolute;
  }
  .qs-overlay-cbox & {
    display: none;
  }
  .icon {
    height: 28px;
    width: 28px;
    background-size: contain;
  }
}

//
// product_image_quickshop_v1
//
.product-quickshop__image__carousel {
  .carousel-controls {
    display: none;
    @include breakpoint($large-up) {
      display: block;
    }
    .slick-next {
      #{$rdirection}: 0;
    }
    .slick-prev {
      #{$ldirection}: 0;
    }
    .slick-next,
    .slick-prev {
      &::before {
        font-size: 25px;
      }
    }
    .qs-overlay-cbox & {
      display: none;
    }
  }
  .carousel-dots {
    .slick-dots {
      margin: 0 0 10px;
    }
    @include breakpoint($large-up) {
      display: none;
    }
  }
  .qs-overlay-cbox & {
    @include breakpoint($large-up) {
      padding-#{$ldirection}: 80px;
    }
  }
}

.product-quickshop__single,
.product-quickshop__carousel {
  overflow: hidden;
  background: $color-quickshop-background;
  max-width: 257px;
  margin: 0 auto;
  .qs-overlay-cbox & {
    @include breakpoint($large-up) {
      max-width: 320px;
    }
    @include breakpoint($large-up) {
      max-width: 400px;
    }
  }
}

.product-quickshop__carousel__slide {
  display: none; // prevent FOUC
  &:first-child,
  .slick-initialized & {
    display: block;
  }
}

.product-quickshop__thumbnail {
  display: none;
  .qs-overlay-cbox & {
    @include breakpoint($large-up) {
      @include centerer(false, true);
      display: block;
      #{$ldirection}: 15px;
    }
  }
}

.product-quickshop__thumbnail__item {
  background-color: $color-quickshop-background;
  display: block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin: 0 0 20px;
  overflow: hidden;
  &.active {
    cursor: default;
  }
  img {
    opacity: 0.5;
    transition: opacity 0.3s;
  }
  &:hover,
  &.active {
    img {
      opacity: 1;
    }
  }
}

.product-quickshop__image {
  position: relative;
  .slick-arrow {
    background-position: 50%;
    background-size: cover;
    height: 40px;
    opacity: 1;
    transition: opacity 1s;
    width: 40px;
  }
  .slick-next {
    #{$rdirection}: -25px;
  }
  .slick-prev {
    #{$ldirection}: -25px;
    @include transform(rotate(180deg));
  }
}
